import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SEO, SectionHeader, SectionCopy } from '../components';

const Section = styled.section`
  position: relative;
  min-height: 100vh;
`;

const Body = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const NotFound = ({ location: { pathname } }) => {
  //

  return (
    <article>
      <SEO title="Page Not Found" description="Page Not Found" />
      <Section>
        <Body>
          <SectionHeader>
            <h1>Ooops.</h1>
          </SectionHeader>
          <SectionCopy>Page not found.</SectionCopy>
        </Body>
      </Section>
    </article>
  );
};

export default NotFound;

NotFound.propTypes = {
  location: PropTypes.object.isRequired,
};
